<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3" v-if="$can('export')">
      <b-col sm>
        <div>
          <button class="btn btn-success" type="button" @click="exportTable">
            Export
          </button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Order code, Name, Status"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Order code, Name, Status ..."
                @keypress="doFilter"
                @keyup.delete="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                @picker="doDateFilter"
              ></range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="name-slot" slot-scope="prop">
        <span>{{ prop.rowData.name }} ({{ prop.rowData.email }})</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <!-- <button
            class="btn btn-info m-1"
            v-if="$can('update_jewelry_order')"
            @click="onAction('edit-item', prop.rowData)"
          >
            Edit
          </button> -->
          <button
            class="btn btn-primary m-1"
            @click="onAction('detail-item', prop.rowData)"
          >
            Detail
          </button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>

      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import accounting from "accounting";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  prop: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      isLoading: false,
      filterText: "",
      errors: {
        code: "",
        message: "",
        status: "",
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `jewelry-orders`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      },
      sortOrder: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc",
        },
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          sortField: "created_at",
          title: "Date",
          titleClass: "center aligned",
        },
        {
          name: "name-slot",
          sortField: "name",
          title: "Name",
          titleClass: "center aligned",
        },
        {
          name: "order_id",
          sortField: "order_id",
          title: "Invoice",
          titleClass: "center aligned",
        },
        {
          name: "payment_status",
          sortField: "payment_status",
          title: "Status Pembayaran",
          formatter: this.paymentChange,
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned",
        },
      ],
    };
  },
  methods: {
    statusChange(val) {
      if (val === "paid" || val === "Success" || val === "success") {
        return "Sudah Dibayar";
      } else if (val === "pending" || val === "Pending") {
        return "Menunggu Pembayaran";
      } else if (val === "processing" || val === "Processing") {
        return "Sedang Diprosess";
      } else if (val === "cancelled" || val === "Cancelled") {
        return "Dibatalkan";
      } else if (val === "awaiting-shipment") {
        return "Dalam Pengiriman";
      } else if (val === "on-hold") {
        return "Ditunda";
      } else if (val === "trash") {
        return "Dibatalkan";
      }
    },
    paymentChange(val) {
      if (val === "paid" || val === "Success" || val === "success") {
        return "Sudah Dibayar";
      } else if (val === "pending" || val === "Pending") {
        return "Menunggu Pembayaran";
      } else if (val === "processing" || val === "Processing") {
        return "Sedang Diprosess";
      } else if (val === "cancelled" || val === "Cancelled") {
        return "Dibatalkan";
      } else if (val === "awaiting-shipment") {
        return "Dalam Pengiriman";
      } else if (val === "on-hold") {
        return "Ditunda";
      } else if (val === "trash") {
        return "Dibatalkan";
      }
    },
    doFilter() {
      this.$events.$emit("filter-set", this.filterText);
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.$emit("filter-reset");
    },
    exportTable() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD");
        startDate = this.$setDate.subtract(6, "days").format("YYYY-MM-DD");
      }

      this.$http
        .get(`jewelry-order/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch((error) => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      this.start_from = this.startDate;
      this.end_to = this.endDate;
      this.moreParams = {
        start_from: this.start_from,
        end_to: this.end_to,
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    formatNumber(value) {
      return "Rp " + accounting.formatNumber(value, 2);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onAction(action, data) {
      if (action == "detail-item") {
        this.$router.push({
          name: "Jewelry Order Detail",
          params: { id: data.id },
        });
      } else {
        this.$router.push({
          name: "Edit Jewelry Order",
          params: { id: data.id },
        });
      }
    },
    onFilterSet(filterText) {
      this.moreParams = {
        filter: filterText,
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onDateFilterReset() {
      this.moreParams = {};
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
  },
  mounted() {
    this.$events.$on("filter-set", (eventData) => this.onFilterSet(eventData));
    this.$events.$on("date-set", (eventData) => this.onDateSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
  },
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
